.dashboard {
  display: flex;
  justify-content: space-evenly;
  margin: 6rem auto 3.5rem auto;
  width: 100%;
}

.dashboard__link {
  text-decoration: none;
  width: 35%;
}

.dashboard__card {
  width: 100% !important;
  height: 100%;
}

.dashboard__size {
  font-size: 5rem;
}

.dashboard__div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 1126px) and (max-width: 1725px) {
  .dashboard__card {
    width: 100% !important;
    height: 100%;
  }
  .dashboard__div {
    margin-top: 2rem;
  }
}

@media (min-width: 961px) and (max-width: 1125px) {
  .dashboard__card {
    width: 100% !important;
    height: 100%;
  }

  .dashboard__div {
    margin-top: 2rem;
  }
}

@media (min-width: 240px) and (max-width: 480px) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    margin: 0.5rem auto 0 auto;
  }

  .dashboard__card {
    width: 100% !important;
    height: 100%;
    margin: 2rem auto;
  }

  .dashboard__link {
    text-decoration: none;
    width: 100%;
  }

  .dashboard__div {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  .dashboard__card {
    width: 85% !important;
    height: 100%;
    margin: 0 auto 2rem auto;
  }

  .dashboard__link {
    text-decoration: none;
    width: 100%;
  }

  .dashboard__div {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }

  .dashboard__link {
    text-decoration: none;
    width: 100%;
  }

  .dashboard__card {
    width: 75% !important;
    height: 100%;
    margin: 0 auto 2rem auto;
  }

  .dashboard__div {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .dashboard__card {
    width: 100% !important;
    height: 100%;
  }
}
