.new__bricoleur {
  display: flex;
  width: 100%;
}

.bricoleur_infro_header {
  display: flex;
}

.bricoleur_img {
  /* my-5 mx-auto */
  width: 65%;
  margin-top: 2.75rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1281px) {
  .bricoleur_container {
    width: 100% !important;
    height: 75vh;
    overflow-y: scroll;
    padding: 15px 0;
  }

  .bricoleur_row {
    width: 100% !important;
    margin: 15px 10px auto !important;
  }

  .bricoleur_row > div {
    margin: 11px 0;
  }

  /* .bricoleur_update {
    width: 75%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  } */

  /*  */
  .bricoleur_update {
    width: 80%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .bricoleur_img > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .bricoleur_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }

  .bricoleur_img {
    /* my-5 mx-auto */
    width: 75%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_check {
    width: 85%;
    margin-top: 2.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .bricoleur_container {
    width: 100% !important;
    height: 75vh;
    overflow-y: scroll;
    padding: 15px 0;
  }

  .bricoleur_row {
    width: 100% !important;
    margin: 15px 10px auto !important;
  }

  .bricoleur_row > div {
    margin: 11px 0;
  }
  /* CSS */

  .bricoleur_info {
    display: flex;
    justify-content: space-between;
    margin: 20px !important;
  }

  .bricoleur_info_chart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .bricoleur_statistic {
    display: flex;
  }

  .bricoleur_chart {
    width: 85% !important;
    margin: 15px 0 !important;
  }

  .bricoleur_update {
    width: 100%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .bricoleur_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }

  .bricoleur_img {
    /* my-5 mx-auto */
    width: 55%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_check {
    width: 85%;
    margin-top: 2.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .bricoleur_info_chart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .bricoleur_statistic {
    display: flex;
  }

  .bricoleur_chart {
    width: 100% !important;
    margin: 15px 0 !important;
  }

  .bricoleur_container {
    width: 100% !important;
    height: 75vh;
    overflow-y: scroll;
    padding: 15px 0;
  }

  .bricoleur_row {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: 15px 10px auto !important;
  }

  .bricoleur_row > div {
    margin: 11px 0;
  }

  .bricoleur_header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  .bricoleur_container {
    width: 100%;
  }

  .bricoleur_col {
    width: 75% !important;
    margin: 10px auto;
  }

  .bricoleur_update {
    width: 100%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_field {
    display: flex;
    flex-direction: column;
  }

  .bricoleur_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .bricoleur_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }

  .bricoleur_img {
    /* my-5 mx-auto */
    width: 55%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_check {
    width: 40%;
    margin-top: 2.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .bricoleur_header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  .bricoleur_info_chart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .bricoleur_container {
    width: 100%;
  }

  .bricoleur_col {
    width: 75% !important;
    margin: 10px auto;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .bricoleur_infro_header {
    display: flex;
    margin-top: 15px;
  }

  .bricoleur_info {
    display: flex;
    flex-direction: column;
  }

  .bricoleur_info_chart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .bricoleur_chart {
    width: 100% !important;
  }

  .bricoleur_statistic > div {
    width: 100% !important;
    margin: 0 auto;
  }

  .bricoleur_infro_combo {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  .bricoleur_statistic {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .bricoleur_infro_combo > div {
    width: 100% !important;
    margin-top: 10px;
  }

  .bricoleur_container {
    width: 100% !important;
    height: 75vh;
    overflow-y: scroll;
    padding: 15px 0;
  }

  .bricoleur_row {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: 15px 10px auto !important;
  }

  .bricoleur_row > div {
    margin: 11px 0;
  }

  .bricoleur_container {
    width: 100%;
  }

  .bricoleur_header {
    display: block;
  }

  .bricoleur_col {
    width: 75% !important;
    margin: 10px auto;
  }

  .bricoleur_link {
    display: flex;
    align-items: center;
    color: white;
    background-color: '#2ACC96';
    border: '#2ACC96';
    text-decoration: none;
    border-radius: 20px;
    margin: 20px;
    padding: 16px 20px;
  }

  .new__bricoleur {
    display: block;
    width: 100%;
  }

  .bricoleur_update {
    width: 100%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_field {
    display: flex;
    flex-direction: column;
  }

  .bricoleur_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .bricoleur_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }

  .bricoleur_img {
    /* my-5 mx-auto */
    width: 60%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_check {
    width: 45%;
    margin-top: 2.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /*  */
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

.bricoleur_info {
  display: flex;
  justify-content: space-between;
  margin: 20px !important;
}

.bricoleur_info_chart {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.bricoleur_statistic {
  display: flex;
}

.bricoleur_chart {
  width: 50% !important;
  margin: 15px 0 !important;
}

.bricoleur_infro_combo {
  display: flex;
  justify-content: space-evenly;
  width: 45%;
}

@media (min-width: 240px) and (max-width: 480px) {
  .bricoleur_info_chart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .bricoleur_chart {
    width: 100% !important;
  }

  .bricoleur_info {
    display: flex;
    flex-direction: column;
  }

  .bricoleur_statistic > div {
    width: 100% !important;
    margin: 0 auto;
  }

  .bricoleur_infro_header {
    display: flex;
    margin-top: 15px;
  }

  .bricoleur_infro_combo {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  .bricoleur_statistic {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .bricoleur_infro_combo > div {
    width: 100% !important;
    margin-top: 10px;
  }

  .bricoleur_container {
    width: 100% !important;
    height: 75vh;
    overflow-y: scroll;
    padding: 15px 0;
  }

  .bricoleur_row {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: 15px 10px auto !important;
  }

  .bricoleur_row > div {
    margin: 11px 0;
  }

  .bricoleur_header {
    display: block;
  }

  .col_brico {
    margin-top: 16px !important;
  }

  .bricoleur_col {
    width: 75% !important;
    margin: 10px auto;
  }

  .new__bricoleur {
    display: block;
    width: 100%;
  }

  .bricoleur_link {
    display: flex;
    align-items: center;
    color: white;
    background-color: '#2ACC96' !important;
    border: '#2ACC96' !important;
    text-decoration: none;
    border-radius: 20px;
    margin: 20px;
    padding: 16px 20px;
  }

  /* w-75 mt-5 mx-auto */
  .bricoleur_update {
    width: 100%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_field {
    display: flex;
    flex-direction: column;
  }

  .bricoleur_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .bricoleur_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }

  .bricoleur_img {
    /* my-5 mx-auto */
    width: 95%;
    margin-top: 2.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bricoleur_update_check {
    width: 55%;
    margin-top: 2.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
