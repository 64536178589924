@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .offer_header {
    display: block;
    /* display: grid; */
    /* grid-template-columns: repeat(3, 3fr); */
  }

  .offer_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .offer_filter,
  .offer_filter_btn {
    width: 100% !important;
    margin: 8px auto;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .offer_header {
    display: block;
    /* display: grid; */
    /* grid-template-columns: repeat(3, 3fr); */
  }

  .offer_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .offer_filter,
  .offer_filter_btn {
    width: 100% !important;
    margin: 8px auto;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* .offer_col {
    display: block;
    width: 75% !important;
    margin: 10px auto;
  } */

  .offer_header {
    display: block;
    /* display: grid; */
    /* grid-template-columns: repeat(3, 3fr); */
  }

  .offer_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .offer_filter,
  .offer_filter_btn {
    width: 100% !important;
    margin: 8px auto;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 240px) and (max-width: 480px) {
  .offer_header {
    display: block;
  }

  .offer_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .offer_filter,
  .offer_filter_btn {
    width: 100% !important;
    margin: 8px 0;
  }
}

.offer_col {
  display: flex;
}
