.toright {
  /* position: absolute; */
  right: 0px;
  margin: 2rem;
  height: fit-content;
}

.toleft {
  margin: 2rem;
  height: fit-content;
}
