.paginated {
  display: flex;
  justify-content: space-between;
}

.paginated_page {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.paginated_page > label {
  margin-right: 0.75rem;
}

.paginated__item > span {
  background-color: rgb(42, 204, 150) !important;
  border-color: rgb(42, 204, 150) !important;
  cursor: pointer;
  overflow-x: scroll;
}
.paginated__item {
  display: block;
}

.offer {
  width: 100%;
}

@media (min-width: 240px) and (max-width: 480px) {
  .paginated__item {
    display: block;
    width: 100%;
  }

  .paginated {
    display: flex;
    align-items: center;
    margin: 0.75rem 0;
    width: 100%;
  }

  .offer {
    width: 100%;
  }

  .paginated_page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .paginated_page > label {
    margin-bottom: 0.35rem;
  }
}

@media (max-width: 270px) {
  .paginated {
    display: flex;
    flex-direction: column;
  }

  .offer {
    width: 100%;
  }
}
