/* w-75 mt-5 mx-auto */
@media (min-width: 240px) and (max-width: 480px) {
  .variable_card {
    width: 100% !important;
  }

  .variable {
    width: 100%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .variable_field {
    margin: 1.75rem 0;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .variable_card {
    width: 100% !important;
  }

  .variable {
    width: 100%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .variable_field {
    margin: 1.75rem 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .variable_card {
    width: 100% !important;
  }

  .variable {
    width: 100%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .variable_field {
    margin: 1.75rem 0;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .variable_card {
    width: 100% !important;
  }

  .variable {
    width: 100%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .variable_field {
    margin: 1.75rem 0;
  }
}

@media (min-width: 1500px) {
  .variable_card {
    width: 90% !important;
  }

  .variable {
    width: 90%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .variable_field {
    margin: 1.75rem 0;
  }
}

.variable,
.variable_card {
  width: 90%;
  margin: 0 auto;
}

.variable_field {
  margin: 2.5rem 0;
}

.variable_btn {
  color: white;
  background: #2acc96;
  border: #2acc96;
  text-decoration: 'none';
}
/*

.variable_btn:hover {
  color: white;
  background: #2acc96;
  border: #2acc96;
  text-decoration: 'none';
} */
