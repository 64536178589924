@media (min-width: 240px) and (max-width: 480px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.footer {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c9eb1;
}
