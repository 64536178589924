.job_new {
  width: 80%;
  margin: auto;
}

.job_add_field {
  margin: 2.5rem 0;
}

.job_btn {
  color: white;
  background: #2acc96;
  border: #2acc96;
  text-decoration: 'none';
  margin: 1.25rem 0;
}

@media (min-width: 240px) and (max-width: 480px) {
  .job_new {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .job_new {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .job_new {
    width: 100%;
    margin: auto;
  }
}
