@media (min-width: 240px) and (max-width: 480px) {
  .city {
    width: 100%;
  }

  .city_field {
    margin: 2.5rem 0;
  }
}

.city {
  width: 70%;
  margin: 0 auto;
}

.city_field {
  margin: 2.5rem 0;
}

.city_btn {
  color: white;
  background: #2acc96;
  border: #2acc96;
  text-decoration: 'none';
}

.city_btn:hover {
  color: white;
  background: #2acc96;
  border: #2acc96;
  text-decoration: 'none';
}
