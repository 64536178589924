.compte_header {
  position: relative;
}

.card_container {
  width: 65%;
  margin: 2.75rem auto 0 auto;
}

.compte_form {
  margin-bottom: 1.75rem;
}

.compte_header > div {
  width: 5rem !important;
  height: 5rem !important;
}

.compte_label {
  position: absolute;
  cursor: pointer;
  width: 20%;
  bottom: 2%;
  left: 65%;
}

.compte_label > img {
  width: 100%;
}

.header_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.compte_form_input {
  display: flex;
  margin: 1.75rem;
}

.compte_btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem 0 !important;
}

.compte_btn_row {
  margin: 2rem 0 0rem 0 !important;
}

@media (min-width: 481px) and (max-width: 900px) {
  .card_container {
    width: 100%;
    margin: 3.75rem auto 0 auto;
  }

  .header_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
  }

  .compte_form_input {
    display: flex;
    flex-direction: column;
    margin: 0.65rem 0.25rem;
  }

  .compte_form_col {
    margin: 0.65rem 0.25rem;
  }

  .compte_btns {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .compte_btn_row {
    margin: 1.25rem 0;
  }
}

@media (min-width: 240px) and (max-width: 480px) {
  .card_container {
    width: 100%;
    margin: 1.5rem auto 1.25rem auto;
  }

  .header_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .compte_form_input {
    display: flex;
    flex-direction: column;
    margin: 0.65rem 0.25rem;
  }

  .compte_form_col {
    margin: 0.65rem 0.25rem;
  }

  .compte_btns {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .compte_btn_row {
    margin: 1.25rem 0;
  }
}
