.container__pos {
  position: relative;
  text-align: center;
  color: white;
  margin: 0 10px;
  width: fit-content;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.top {
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: red;
  border: 1px solid red;
  border-radius: 50%;
}

/* @media (min-width: 360px) and (max-width: 720px) {
  .container__pos {
    display: grid;
    grid-template-columns: 2;
  }
} */

.img__container {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
}

@media (min-width: 240px) and (max-width: 759px) {
  .img__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .img__container > div {
    margin: 10px;
  }
}

@media (min-width: 760px) and (max-width: 1500px) {
  .img__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .img__container > div {
    margin: 10px;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .img__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .img__container > div {
    margin: 10px;
  }
}

@media (min-width: 240px) and (max-width: 480px) {
  .top {
    position: absolute;
    top: 5%;
    right: -1%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: red;
    border: 1px solid red;
    border-radius: 50%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .top {
    position: absolute;
    top: 5%;
    right: -1%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top {
    position: absolute;
    top: 5%;
    right: -1%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 50%;
  }
}

@media (min-width: 1281px) {
  .top {
    top: 5%;
    right: -1%;
  }
}
