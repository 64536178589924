.new__user {
  display: flex;
  width: 100%;
}

.user_update {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
}

@media (min-width: 1281px) {
  .user_update {
    width: 80%;
    margin: 2.75rem auto;
  }

  .user_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .user_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .user_update {
    width: 80%;
    margin: 2.75rem auto;
  }

  .user_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .user_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .user_header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }

  .user_col {
    width: 75% !important;
    margin: 15px auto;
  }

  .user_update {
    width: 80%;
    margin: 2.75rem auto;
  }

  .user_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .user_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .user_header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }
  /* .user_header {
    display: block;
  }

  */
  .user_col {
    width: 75% !important;
    margin: 10px auto;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .user_header {
    display: block;
  }

  .user_col {
    width: 75% !important;
    margin: 10px auto;
  }

  .new__user {
    display: block;
    width: 100%;
  }

  .user_update {
    width: 80%;
    margin: 2.75rem auto;
  }

  .user_update_field {
    display: flex;
    flex-direction: column;
  }

  .user_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .user_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 240px) and (max-width: 480px) {
  .user_header {
    display: block;
  }

  .col_user {
    margin-top: 16px !important;
  }

  .user_col {
    width: 75% !important;
    margin: 10px auto;
  }

  .new__user {
    display: block;
    width: 100%;
  }

  .user_update {
    width: 80%;
    margin: 2.75rem auto;
  }

  .user_update_field {
    display: flex;
    flex-direction: column;
  }

  .user_update_field > div {
    margin: 0.25rem 0 1.75rem 0;
  }

  .user_btn {
    color: white;
    background: #2acc96;
    border: #2acc96;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 1.5rem auto;
  }
}

.col_user {
  margin-top: 25px !important;
}

.new__user {
  width: 100%;
  margin-top: 20px !important;
}
